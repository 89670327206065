<template>
<div class="memberRecharge-view">
    <div class="statement">
      <center><b>郑重声明</b></center>
      <div>
        <span>
          当前，市面上存在大量盗版影视会员卡,只需要十几块钱就可
        以看全网所有影视平台一年的那种。再次,我司郑重声明:折扣宝
        只做官方正版影视会员,所有会员均为对应影视官方用户自有账号
        直冲,且48小时内到账。
        </span>
        <p>(注:市面.上盜版影视卡属于违法犯罪行为)</p>
      </div>
    </div>


  <van-row class="memberRecharge-list">
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/tengxunsp.jpg">
      <div class="memberRecharge-list-title">
        【充值填写QQ】腾讯视频VIP会员12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/aiqiyisp.jpg">
      <div class="memberRecharge-list-title">
        【新用户首冲】爱奇艺VIP黄金会员年卡12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/aiqiyisp.jpg">
      <div class="memberRecharge-list-title">
        【新用户首冲】爱奇艺VIP黄金会员年卡12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/aiqiyisp.jpg">
      <div class="memberRecharge-list-title">
        【新用户首冲】爱奇艺VIP黄金会员年卡12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/aiqiyisp.jpg">
      <div class="memberRecharge-list-title">
        【新用户首冲】爱奇艺VIP黄金会员年卡12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
    <van-col span="12" class="memberRecharge-list-view">
      <img src="../assets/images/aiqiyisp.jpg">
      <div class="memberRecharge-list-title">
        【新用户首冲】爱奇艺VIP黄金会员年卡12个月
      </div>
      <div class="memberRecharge-price">￥118.00</div>
    </van-col>
  </van-row>


</div>
</template>

<script>
export default {
  name: "MemberRecharge"
}
</script>

<style scoped>
  .memberRecharge-view{
    padding:10px
  }
  .statement{
    padding: 8px 10px;
    color: #F40;
    background: #FFFFFF;
    border: 1px solid #F40;
    border-radius: 10px;
  }
  .statement span{
    text-align: left;
    display: inline-block;
    text-indent: 2em;
  }
  .statement p {
    text-align: right;
  }
  .memberRecharge-list{
    text-align: center;
    margin: 10px 0;
    padding: 10px 0;
    background: #FFFFFF;
  }
  .memberRecharge-list-view{
    width: 90%;
  }
  .memberRecharge-list-view img{
    width: 90%;
    margin: 15px 0;
    border:1px solid #F40;
  }
  .memberRecharge-list-title,.memberRecharge-price{
    width: 90%;
    padding: 2px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .memberRecharge-price{
    color: #F40;
    font-size: 18px;
    text-align: left;
  }
</style>
